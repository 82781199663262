@import "../../styles/util";

.moreBtnMaindivAll {
  :global(.offcanvas-header) {
    padding: 12px 11px 0px;
  }

  :global(.btn-close:focus) {
    outline: none !important;
    box-shadow: none !important;
  }

  :global(.offcanvas-body) {
    padding: 0px 36px;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      display: block;
    }

    a {
      display: block;
      padding-bottom: 20px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
      font-family: "Sora", sans-serif;

      &:hover {
        color: #11326a;
      }
    }
  }
}

.searchWrapperMain {
  position: relative;
  padding: 0 19px;
  max-width: 600px;
  margin: 0 auto;

  :global(.btn-close:focus) {
    outline: none !important;
    box-shadow: none !important;
  }

  .searchWrapperMainDiv1 {
    position: absolute;
    right: 0;
    top: 6px;
  }

  .searchWrapperMainDiv2 {
    position: absolute;
    left: 0;
    top: 7px;
  }

  input {
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 10px;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 0;
  }
}

.serchWrapperBodymain {
  :global(.offcanvas-body) {
    overflow-y: hidden !important;
  }
}

.searchareaDiv {
  span {
    font-size: 14px;
    color: #626262;
    font-weight: 400;
    display: block;
    margin-bottom: 10px;
  }

  ul {
    margin: 0;
    padding: 0;
    max-height: 109px;
    overflow: auto;

    li {
      display: block;
      color: #000;
      font-size: 15px;
      font-weight: 500;
      padding-bottom: 10px;

      a {
        &:hover {
          color: #83b200;
        }
      }
    }
  }
}

.SubheaderWrapper {
  transition: 0.2s all ease-in-out;
  padding: 3px 0 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 800;
  background-color: $light-color;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);

  @media screen and (max-width:1199px) {
    display: none;
  }

  .HeaderSection1 {
    position: relative;

    .headerRightTopRow {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0px;

      .moreBtnmain {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        background: #11336f;
        border-radius: 30px;
        line-height: 20px;

        padding: 1px 13px 4px;
        transition: 0.4s;

        text-align: left;

        &:hover {
          background: #8faa44;
        }

        @media screen and (max-width: 1199px) {
          display: none !important;
        }
      }

      ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        padding-left: 0;
        margin-bottom: 0;

        @media screen and (max-width: 570px) {
          display: none !important;
        }

        li {
          padding: 0 8px;

          &:last-child {
            padding-right: 0;

            span {
              display: block;
              width: 100%;
              height: 2px;
              background-color: black;
            }
          }
        }
      }
    }

    :global(.navbar-expand-xl) {
      flex-wrap: wrap !important;
    }

    :global(.navbar-brand) {
      img {
        width: 124px;

        @media screen and (max-width: 1199px) {
          width: 100px;
        }
      }
    }
  }

  .HeaderSection {
    background: #fff;

    @media screen and (max-width: 1199px) {
      padding: 8px 0px;
      display: none;
    }

    :global(.navbar) {
      padding: 0;
      position: inherit;

      :global {
        .navbar-toggler {
          border: none;
          outline: none;

          &:focus {
            box-shadow: none;
          }

          span {
            display: block;
            height: 2px;
            width: 35px;
            background-color: #fff;
            transform: rotate(45deg) translate(6px, 5px);
            transform-origin: center;
            transition: all 0.3s;

            &+span {
              margin-top: 10px;
              transform: rotate(-45deg) translate(3px, -2px);
              transform-origin: center;
            }
          }

          &.collapsed {
            span {
              transform: rotate(0) translate(0);
              transition: all 0.3s;

              &+span {
                transform: rotate(0) translate(0);
              }
            }
          }
        }
      }

      :global {
        .offcanvas-body {
          .navbar-nav {
            flex-direction: column;
          }
        }
      }

      .headerRightBottomRow {
        >ul {
          display: flex;
          align-items: center;
          list-style-type: none;
          padding-left: 0;
          margin-bottom: 0;
          justify-content: space-between;
       
          gap: 15px;

          >li {
            padding: 3px 0px;

            &:last-child {
              padding-right: 0;
            }

            &:first-child {
              padding-left: 0;
            }

            :global(.nav-link) {
              font-size: 14px;
              font-weight: 400;
              padding: 14px 0px;
              line-height: 1;
              font-family: "Sora", sans-serif;
              color: #000000;

              transition: 0.2s all ease-in-out;

              &:hover {
                color: #8faa44;
              }
            }

            .aboutUsMegaMenuWrapper {
              opacity: 0;
              position: absolute;
              // top: 100%;
              left: 0;
              width: 100%;
              top: 50px;
              background-color: $light-color;
              transition: 0.2s all ease-in-out;
              box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
              // transform: translateY(20px);
              pointer-events: none;
              z-index: 100000;

              .aboutUsMegaMenuContents {
                padding: 22px;
                position: relative;
                z-index: 1;
                min-height: 200px;
                h4 {
                  color: #0642a7 !important;
                  font-weight: 600 !important;
                }

                p {
                  color: #0642a7;
                  font-size: size(16);
                  font-weight: 600;
                  margin-bottom: 5px;

                  @include max(1599.98) {
                    margin-bottom: 5px;
                  }

                  a {
                    color: #0642a7;
                  }
                }

                span {
                  color: #8faa44;
                  font-size: 13px;
                  font-weight: 600;
                  margin-bottom: 5px;
                  display: block;
                  font-family: "Sora", sans-serif;

                  @include max(1599.98) {
                    margin-bottom: 5px;
                  }

                  a {
                    color: #8faa44;
                  }
                }

                .commonListingUl {
                  padding: 0;
                  margin: 0;
                  margin-bottom: 20px;
                  list-style-type: none;
                  position: relative;

                  @include max(1599.98) {
                    margin-bottom: 20px;
                  }

                  li {
                    margin-bottom: 10px;
                    position: relative;
                    padding-left: 15px;

                    @include max(1599.98) {
                      margin-bottom: 10px;

                      &:last-child {
                        margin-bottom: 0;
                      }
                    }

                    &:last-child {
                      margin-bottom: 0;
                    }

                    a {
                      font-size: 13px;
                      font-weight: 400;
                      transition: 0.2s all ease-in-out;
                      color: #000000;
                      font-family: "Sora", sans-serif;

                      &:hover {
                        color: $secondary-color;
                      }

                      &:hover i {
                        visibility: visible;
                      }
                    }

                    i {
                      font-style: normal;
                      visibility: hidden;
                      position: absolute;
                      right: 0;
                      top: 0;
                      background: #fff;
                      padding: 5px 5px;
                      font-size: 10px;
                      font-weight: 400;
                      color: #000;
                      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
                    }

                    &::before {
                      content: url("../../assets/icons/arrow-right-with-cut.svg");
                      position: absolute;
                      width: 10px;
                      height: 15px;
                      left: 0;
                    }
                  }
                }

                h6 {
                  font-family: "Sora", sans-serif;
                  font-size: size(16);
                  font-weight: 600;
                  margin-bottom: 30px;
                  max-width: 354px;
                  line-height: 1;
                }

                .aboutmegamenuimg {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  max-width: 350px;
                  z-index: -1;
                  opacity: 0.3;
                }
              }
            }

            &:hover {
              .aboutUsMegaMenuWrapper {
                opacity: 1;
                // transform: none;
                pointer-events: all;
              }
            }
          }
        }
      }
    }
  }
}

:global(.offcanvas-end) {
  :global(.offcanvas-header) {
    justify-content: flex-end;
  }

  :global(.offcanvas-body) .headerRightBottomRow {
    :global(.nav-link) {
      font-size: size(16);
      font-weight: 500;

      @include max(1599.98) {
        font-size: size(13);
      }
    }
  }
}

:global(.modal-open) {
  .headerRightTopRow {
    display: none;
  }

  .headerRightBottomRow {
    ul {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
        >div {
          display: none;
        }
      }
    }

    @include max(767.98) {
      padding-top: 20px;
    }
  }
}

.sectionMenuMain {
  background: #333 !important;
}

.mobileMainMenu {

  // display: none;
  :global(.offcanvas-header) {
    padding: 0 0 26px !important;
  }

  :global(.btn-close:focus) {
    box-shadow: none !important;
  }

  :global(.btn-close) {
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: #8faa44 url(../../assets/icons/close-btn.png) center no-repeat !important;
    opacity: 1 !important;
    border-radius: 50%;
    background-size: 10px !important;
    text-align: right;
    position: absolute;
    right: 17px;
    top: 13px;
  }

  .innerDropdownMain {
    :global(.accordion-item) {
      border: none !important;
      padding: 0px !important;
      border-top: 1px #143c82 solid !important;
    }

    :global(.accordion-button) {
      color: #d5d220 !important;
    }
    h4 {
      color: #0642a7 !important;
      font-weight: 700 !important;
    }
  }

  :global(.accordion-body) {
    padding: 0px 0px 10px !important;

    span {
      color: #e2f274;
      font-weight: 500;
      margin-bottom: 10px;
      display: block;
      font-size: 14px;
      font-family: "Sora", sans-serif;
    }
  }

  :global(.offcanvas) {
    background: var(--primary) !important;
  }

  :global(.accordion-header) {
    position: relative;

    img {
      width: 10px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  :global(.accordion-item) {
    border: none !important;
    padding: 0px !important;
    border-bottom: 1px #143c82 solid !important;

    ul {
      margin: 0;
      padding: 0 8px 0px;
      list-style: none;

      a {
        color: #fff;
        padding-bottom: 11px;
        font-size: 13px;
        font-family: "Sora", sans-serif;
        font-weight: 400;
        display: block;
      }
    }
  }

  :global(.accordion-button) {
    border: none !important;
    position: relative;
    padding: 0px !important;
    font-size: 20px;
    color: #8faa44 !important;
    font-weight: 600;
    padding: 5px 0px 13px !important;

    a {
      font-size: 20px;
      color: #8faa44 !important;
      font-weight: 600;
    }

    &:after {
      background-image: url(../../assets/icons/dropdown.png) !important;
      position: absolute !important;
      right: 0;
      top: 11px;
      width: 20px !important;
      height: 20px !important;
      content: "" !important;
      z-index: 1000 !important;
      background-size: 14px;
    }
  }

  @media screen and (max-width: 1199px) {
    display: block;
  }
}

.menuBtnmain {
  display: none;

  img {
    width: 27px;
  }

  @media screen and (max-width: 1199px) {
    display: block;
  }
}



.headerLogo{
  width: 75px;
}
.enquireBtn{
  all: unset;
  background-color: #10316A;
  color: #fff;
  border-radius: 0px;
  padding: 5px 25px;
  font-size: 16px;
  font-weight: 700;
}

.newListing {
  position: relative;
  padding: 15px;
  &::before {
    content: url("../../assets/icons/arrow-right-with-cut.svg");
    position: absolute;
    width: 10px;
    height: 15px;
    left: 0;
  }
}

.newListingUl {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  list-style-type: none;
  position: relative;

  @include max(1599.98) {
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 15px;

    @include max(1599.98) {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      font-size: 13px;
      font-weight: 400;
      transition: 0.2s all ease-in-out;
      color: #000000;
      font-family: "Sora", sans-serif;

      &:hover {
        color: $secondary-color;
      }

      &:hover i {
        visibility: visible;
      }
    }

    i {
      font-style: normal;
      visibility: hidden;
      position: absolute;
      right: 0;
      top: 0;
      background: #fff;
      padding: 5px 5px;
      font-size: 10px;
      font-weight: 400;
      color: #000;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
      z-index: 100;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 10px;
      // transform: translateY(-50%);
      width: 7px;
      height: 7px;
      background-color: #83b200;
      border-radius: 50%;
    }
  }
}