$primary-color: var(--primary);
$secondary-color: var(--secondary);
$secondary-light-color: var(--secondary-light);
$red-color: var(--red);
$yellow-color: var(--yellow);
$orange-color: var(--orange);
$pink-color: var(--pink);
$blue-color: var(--blue);
$grey-color: var(--grey);
$black-color: var(--black);
$white-color: var(--white);
$white-color: var(--white);
$light-color: var(--light);
$light-grey-color: var(--light-grey);
$blue-alt: var(--blue-alt);
$hover-color: var(--hover-color);
$btn-grey: var(--btn-grey);
$btn-grey-transparent: var(--btn-grey-transparent);
$border-color: var(--border-color);
$success: #19a56e;
$danger: #dd3545;
$stroke-color: var(--stroke);
$stroke-grey-color: var(--stroke-grey);
$card-grey: var(--card-grey);
$text-alt-color: var(--text-alt);
$grey-alt-color: var(--grey-alt);
:root {
    --primary: #10316A;
    --secondary: #83B200;
    --light: #FFFFFF;
    --black: #000000;
}