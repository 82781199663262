@import "../../styles/util";

.footerMobileMain{
    :global(.accordion-item){
        border: 1px solid #122A51!important; border-left: none!important;
        border-right: none!important; border-top: none!important;
    }
 
}
.footerMobileMain{
    :global{
        .accordion-header{
            h5 {
                color: #FFFFFF;
                font-weight: 400;
            }
        }
        .accordion-button{
            &::after{
                display: none!important;
            }
            svg{ position: absolute; right: 0; top: 21px;}
        }
        .accordion-body{
            padding: 0px!important;
            ul{
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 0;
                li{
                    margin-bottom: 10px;
                }
            }
        }
    }
}
   
    .footerListBtn{
        color: $light-color;
        font-size: size(18);
        background: none;
        border: none;
        cursor: pointer;
    }

    .phoneWrapper {
        margin-bottom: 30px;
        a {
            display: block;
            color: $light-color;
            font-size: size(18);
            transition: 0.2s all ease-in-out;
            &:hover {
                color: $secondary-color;
            }
        }
    }

    .socialmediaWrapper {
        h5 {
            margin-bottom: 20px;
            padding-bottom: 0;
            border: none;
            color: $light-color;
        }
        ul {
            display: flex;
            align-items: center;
            li {
                margin-bottom: 0;
                padding: 0 22px;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
                a {
                    svg {
                        path {
                            transition: 0.2s all ease-in-out;
                        }
                    }
                }
                &:hover {
                    a {
                        svg {
                            path {
                                fill: $secondary-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .addressWrapper {
        margin-bottom: 40px;
        p {
            color: $light-color;
            padding-bottom: 25px;
        }
        >a {
            border-bottom: none;
            span {
                color: $light-color;
            }
        }
    }