.pageContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .imgWrapper {
    max-width: 400px;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }

  .contentWrapper {
    text-align: center;
    h6 {
      font-size: 80px;
      font-weight: 900;
      color: #1b4e9b;
      margin: 0;
      font-family: sans-serif;
    }
    p {
      font-size: 30px;
      font-weight: 500;
      color: #000;
      font-family: sans-serif;
    }
    button {
      background-color: #bccf31;
      border-radius: 5px;
      border: none;
      color: #fff;
      padding-inline: 25px;
      padding-block: 8px;
      font-family: sans-serif;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
