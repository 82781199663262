.fixedWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1001;
  top: 75px;
  transition-delay: 2s;

  .top {
    top: 54px;
  }
  background-image: linear-gradient(transparent, #000000a8);
  .searchWrapper {
    .searchBox {
      background-color: #f2f2f2;
      width: 350px;
      // height: 300px;
      float: right;
      padding: 20px;
      border-radius: 5px;
      .head {
        position: relative;
        input {
          background-color: white;
          padding: 10px 20px;
          border: 0;
          border-radius: 5px;
          width: 100%;
        }
        button {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
      .list {
        ul {
          padding: 0;
          margin-top: 15px;
          max-height: 210px;
          overflow-y: scroll;
          li {
            list-style: none;
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .keySearches {
            font-size: 14px;
            font-weight: bold;
            color: #113069;
          }
        }
      }
    }
  }
}
