@import "../../styles/util";

.desclaimerContainer {
  width: 100%;
  font-size: 13px;
  // padding: 10px 5px 0px 5px;
  padding: 10px;
  background-color: #f1f1ef;
  z-index: 1000;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease all;
  position: fixed;
  bottom: 0;
  left: 0;
  @media screen and (max-width: 768px){
    display: none;
  }

  p {
    max-width: 1350px;
    margin: 0 auto;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    // line-height: 1.2em;
    margin-bottom: 0.5em;
  }

  .official {
    color: red;
  }
  .link {
    font-weight: bold;
    color: black;
    text-decoration: none;
    transition: text-decoration 0.3s ease;
  }
  .link:hover {
    text-decoration: underline;
  }

  .btn {
    width: 30px;
    height: 30px;
    padding: 4px;
    padding: 4px 8px;
    background: #0452a7;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    transition: 0.3s ease all;
  }
  .btn:hover {
    background: white;
    color: #0642a7;
    transition: 0.3s ease all;
  }
}
