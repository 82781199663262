@import "../../styles/util";
.footer {
  padding: size(40) 0;
  background-color: #0c1e3c;
  padding-bottom: 22px;

  @include max(767.98) {
    padding: size(30) 0;
  }
  .footerWrapper {
    border-bottom: 1px solid rgba(57, 82, 123, 0.18);
    padding-bottom: 40px;
    @include max(991.98) {
      padding-bottom: 50px;
    }
    @media screen and (max-width: 570px) {
      padding-bottom: 20px;
    }
    .whatsappWrapper {
      display: flex;
      align-items: center;
      @include max(991.98) {
        padding-bottom: 20px;
        flex-direction: column;
        justify-content: flex-start;
      }
      @media screen and (max-width: 767px) {
        padding-bottom: 0px;
      }
      @media screen and (max-width: 570px) {
        padding-bottom: 0px;
      }
      figure {
        margin-bottom: 0;
        @include max(767.98) {
          padding-bottom: 10px;
          text-align: center;
        }
        img {
          width: 120px;
          @include max(767.98) {
            max-width: 60%;
          }
        }
      }
      h4 {
        color: #ffffff;
        max-width: 288px;
        padding-left: 30px;
        font-weight: 500;
        line-height: 34px;
        font-size: 36px;
        @media screen and (max-width: 992px) {
          line-height: 34px;
          margin-bottom: 0px;
          font-size: 27px;
          margin-top: 0px;
          text-align: center;
          max-width: 100%;
          padding-left: 0px;
        }
        @include max(767.98) {
          padding-left: 0;
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }
    }
    .newsletterWrapper {
      p {
        font-size: 28px;
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 9px;
        line-height: 40px;
        @include max(991.98) {
          font-size: 22px;
          text-align: center;
        }
      }
      .newsletterInput {
        form{
          display: flex;
        max-width: 630px;
        width: 100%;
        @media screen and (max-width: 992px) {
          max-width: 100%;
        }
        input {
          background: transparent;
          border: 1px solid #31415c;
          outline: none;
          color: #ffffff;
          max-width: 520px;
          width: 100%;
          padding: 15px 10px 10px 20px;
          font-size: 16px;
          font-weight: 400;
          @media screen and (max-width: 992px) {
            max-width: 100%;
          }
          &::placeholder {
            font-size: size(20);
            font-weight: 400;
            color: #ffffff;
            opacity: 0.3;
          }
        }
        button {
          background: transparent;
          border: 1px solid #31415c;

          border-left: none;
          outline: none;
          max-width: 87px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px 10px 15px 10px;
          &:active{
            background-color: #8faa44;
          }
          svg {
            width: 15px !important;
            height: 15px !important;
          }
          @include max(767.98) {
            padding: 10px;
            max-width: 60px;
          }
        }
        }
      }
    }
    .footerLinksRow {
      margin-top: 56px;
      --bs-gutter-x: 180px;
      @include max(1400) {
        --bs-gutter-x: 70px;
      }
      > div {
        h5 {
          color: #ffffff;
          padding-bottom: 19px;
          margin-bottom: 24px;
          border-bottom: 1.5px solid #21375d;
          font-size: 25px;
          line-height: 24px;
          font-family: "Darker Grotesque", sans-serif;
        }
        ul {
          padding-left: 0;
          margin-bottom: 0;
          list-style-type: none;
          li {
            &:not(:last-child) {
              margin-bottom: 10px;
            }
            .footerListBtn {
              opacity: 0.75;
              color: $light-color;
              font-size: size(18);
              background: none;
              border: none;
              cursor: pointer;
              transition: 0.2s all ease-in-out;
              &:hover {
                color: #8faa44;
              }
            }
          }
        }
        .phoneWrapper {
          margin-bottom: 30px;
          opacity: 0.75;
          a {
            display: block;
            color: var(--light);
            font-size: 22px;
            transition: 0.2s all ease-in-out;
            line-height: 26px;
            font-weight: 300;
            &:hover {
              color: #8faa44;
            }
          }
        }
        .addressWrapper {
          margin-bottom: 30px;
          opacity: 0.75;
          p {
            color: var(--light);
            padding-bottom: 25px;
            font-size: 14px;
            font-weight: 300;
            line-height: 23px;
          }
          > a {
            border-bottom: none;
            span {
              color: #c3c7cf;
              font-weight: 400;
              font-size: 14px;
              padding-right: 4px;
              svg {
                top: 2px;
                position: relative;
              }
            }
          }
        }
        .socialmediaWrapper {
          h5 {
            margin-bottom: 20px;
            padding-bottom: 0;
            border: none;
            color: #ffffff;
            opacity: 1;
            font-size: 18px;
            margin-bottom: 12px;
            font-weight: 600;
            font-family: "Darker Grotesque", sans-serif;
          }
          ul {
            display: flex;
            align-items: center;
            li {
              margin-bottom: 0;
              padding: 0 15px;
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
              a {
                svg {
                  path {
                    transition: 0.2s all ease-in-out;
                  }
                }
              }
              &:hover {
                a {
                  svg {
                    path {
                      fill: #8faa44;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .mobileFooterWrapper {
    display: block;
    :global(.accordion) {
      :global(.accordion-header) {
        :global(.accordion-button) {
          svg {
            color: $light-color;
            transform: rotate(-90deg);
          }
          &:global(.collapsed) {
            svg {
              color: $light-color;
              transform: rotate(90deg);
            }
          }
        }
      }
      :global(.accordion-body) {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
  .footerEnd {
    text-align: center;
    padding-top: 20px;
    p {
      color: $light-color;
      opacity: 0.4;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}
