.breadCrumbMain {
  margin-top: 0px;
  .breadcrumbList {
    margin: 31px 0 0;
    padding: 14px 0px 0px;
    .crumb {
      display: inline-block;
      position: relative;
      font-size: 15px;
      font-weight: 400;
      color: #000000;
      padding-right: 20px;
      margin-top: 5px;
      &:last-child {
        padding-right: 0px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      &::after {
        // position: absolute;
        // right: 0px;
        // top: 10px;
        // content: "";
        // width: 7px;
        // height: 7px;
        // border-radius: 50%;
        // background: var(--primary);

        // content: "";
        // position: absolute;
        // top: 50%;
        // right: -1px;
        // transform: translateY(-50%);
        // width: 11px;
        // height: 8px;
        // border-left: 10px solid var(--primary);
        // border-top: 6px solid transparent;
        // border-bottom: 6px solid transparent;

        content: "";
        position: absolute;
        top: 50%;
        right: 13px;
        transform: translateY(-49%) rotate(320deg);
        width: 7px;
        height: 7px;
        border: solid var(--primary);
        border-width: 0 2px 2px 0;
      }
      a {
        font-size: 14px;
        font-weight: 400;
        color: #0000006b;
        font-family: "Sora", sans-serif;
        background: none;
        border: none;
        padding: 0px;
        text-transform: capitalize;
        margin-right: 10px;
        &:hover {
          color: #000000;
        }
      }
    }
  }
}
