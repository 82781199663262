@import "../../styles/util";
@mixin commonHeadMain {
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 25px;
  line-height: 40px;
  font-family: "Darker Grotesque", sans-serif;
  @media screen and (max-width: 1199px) {
    font-size: 32px;
    margin-bottom: 15px;
    line-height: 31px;
    max-width: 100%;
  }
}
@mixin commonPara {
  font-size: 14px;
  font-family: "Sora", sans-serif;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 20px;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    font-size: 13px !important;
    line-height: 28px;
  }
}

.sectionOne {
  margin-top: 30px;
  background-image: url(../../assets/images/acedemic-programmmes-school-of-business/bgimage-1.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 7%;
  h2 {
    @include commonHeadMain();
    line-height: 1;
  }
  .col-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  p {
    @include commonPara();
  }
  .col-md-6 {
    img {
      width: 100%;
    }
  }
}

.sectionTwo {
  margin-top: 48px;
  background-color: #10316a;
  background-image: url(../../assets/images/acedemic-programmmes-school-of-business/bgimage-2.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 7%;
  h2 {
    @include commonHeadMain();
    color: #fff;
  }
  .mainTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 53px;
  }
  .mainContent {
    ul {
      padding: 33px 10px;
      li {
        font-family: "Darker Grotesque";
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 2;
        position: relative;
        list-style: none;
        &::before {
          background: url(../../assets/images/aboutus-mission-vission/bulletpoint.svg)
            no-repeat;
          content: "";
          height: 13px;
          left: -30px;
          position: absolute;
          top: 10px;
          width: 13px;
        }
        span {
          color: #8faa44;
        }
      }
    }
  }
}

.sectionThree {
  margin-top: 30px;
  h5 {
    font-size: 25px;
    font-weight: 700;
    color: #000;
    font-family: "Darker Grotesque";
  }
  h3 {
    color: #000;
    font-family: "Darker Grotesque";
    font-size: 34px;
    font-weight: 700;
    line-height: normal;
    margin-top: 33px;
  }
  p {
    color: #000;
    font-family: Sora;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  .termWiseMain {
    display: flex;
    flex-wrap: wrap;
    .termWiseSub {
      display: flex;
      align-items: stretch;
    }
  }
  .termWiseContent {
    flex: 1;
    padding: 20px;
    background: #f7f7f7;
    margin: 20px 0;
    h5 {
      font-size: 23px;
      font-weight: 500;
      border-left: 7px solid #8faa44;
      padding-left: 10px;
      margin-bottom: 10px;
    }
    ul {
      padding-left: 0px !important;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #34343421;
        padding: 12px 0;
        span {
          color: #000;
          font-family: Sora;
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  .tableMain {
    table {
      width: 100%;
      margin-top: 30px;
      th,
      td {
        padding: 15px;
        border: 1px solid #0003;
        color: #000;
        font-family: Sora;
        line-height: normal;
        width: 50%;
      }
      th {
        background: #dee4ed;
        font-size: 14px;
      }
      td {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .ambassdorContainer {
    h2 {
      color: #000;
      text-align: center;
      font-family: "Darker Grotesque";
      font-size: 52px;
      font-weight: 600;
      line-height: 60px;
    }
    ul {
      padding: 0;
      text-align: center;
      margin: 0 -15px;
      @media screen and (max-width: 992px) {
        margin: 0 -10px;
      }
      li {
        display: inline-block;
        list-style: none;
        padding: 0 16px;
        width: 20%;
        margin-bottom: 20px;
        @media screen and (max-width: 992px) {
          width: 50%;
          padding: 0 10px;
          margin-bottom: 15px;
        }
        @media screen and (max-width: 767px) {
          padding: 0 10px;
          width: 100%;

          margin-bottom: 10px;
        }
      }
    }
    .ambassdorCard {
      .ambassdorCard {
        width: 20%;
        .profileImg {
          background: #f1f1f1;
        }
      }
    }
  }
  .tabMainSec {
    @media screen and (max-width: 992px) {
      display: none;
    }
    :global(.nav-tabs) {
      border-bottom: none;
      padding: 0px 0px 20px;
      justify-content: start;
      margin-bottom: 0px;
    }
    :global(.nav-link) {
      border: none !important;
      padding: 0px 0px 4px;
      margin-right: 36px;
      color: #000;
      font-family: "Darker Grotesque";
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      &:hover {
        border: none;
      }
    }
    :global(.nav-link.active) {
      position: relative;
      color: #000;
      font-family: "Darker Grotesque";
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      &:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #8faa44;
      }
    }
  }
  .tabMainSecMobile {
    display: none;
    @media screen and (max-width: 992px) {
      display: block;
    }

    :global(.accordionResearch) {
      :global(.accordion-item) {
        border-bottom: 1px #ddd solid !important;
      }
      :global(.accordion-body) {
        padding: 0px 28px 30px 0px;
        @media screen and (max-width: 992px) {
          padding: 0px 0px 30px 0px;
        }
        label {
          color: #000000;
          font-size: 16px;
          font-family: "Sora", sans-serif;
          font-weight: 500;
          margin-bottom: 10px;
        }
        p {
          @include commonPara();
          margin-bottom: 20px;
          color: black;
        }
      }
      :global(.accordion-button) {
        padding: 20px 61px 20px 0px;
        color: #000000;
        font-size: 17px;
        font-weight: 500;
        font-family: "Sora", sans-serif;
        @media screen and (max-width: 992px) {
          font-size: 15px;
        }
        &:after {
          background: url(../../assets/images/research-publication/accordion-icon1.png)
            right center no-repeat;
          width: 39px;
          height: 39px;
          transform: rotate(0deg);
          background-size: 37px;
          position: absolute;
          right: 0;
          height: 100%;
        }
      }
      :global(.accordion-button:not(.collapsed)::after) {
        background: url(../../assets/images/research-publication/accordion-icon2.png)
          right center no-repeat;
        width: 39px;
        height: 39px;

        background-size: 37px;
        position: absolute;
        right: 0;
        height: 100%;
      }
      :global(.accordion-button:not(.collapsed)) {
        font-weight: 700;
      }
    }
  }
}

.CardSection {
  padding: 60px 0;
  @media screen and (max-width: 767px) {
    padding: 40px 0 !important;
  }
  @media screen and (max-width: 1119px) {
    padding: 40px 0 !important;
  }
  .CardSpace {
    padding: 5px 0px;
    position: relative;
    top: 18px;
  }

  h1 {
    @include commonHeadMain();
    color: #000000;
    text-align: center;
    margin-bottom: 10px;
  }
  .LoadStyle {
    display: flex;
    justify-content: center;
  }
}

.foundingImgWrapper {
  background-color: #f1f1f1;
  height: 165px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  img {
    width: 70%;
  }
}

.TextCls {
  h2 {
    font-size: 19px !important;
    line-height: 1 !important;
    text-align: left !important;
  }
}

.foundingBgTxt {
  text-align: left !important;
  h2 {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 4px;
    font-family: "Darker Grotesque", sans-serif;
    line-height: 1;
    min-height: 31px;
    @media screen and (max-width: 767px) {
      font-size: 13px;
      margin-bottom: 2px;
    }
  }
  label {
    font-size: 14px;
    color: #555555;
    font-weight: 500;
    font-family: "Darker Grotesque", sans-serif;
    line-height: 20px;
    display: block;
    margin-bottom: 0px;
    text-align: left;
    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
      font-size: 12px;
    }
  }
  &:hover {
    img {
      transform: scale(1.04);
    }
  }
  .foundingImgWrapper {
    overflow: hidden;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .TeamImg {
      width: 100%;
      transition: 0.4s;
    }
  }
  .TextCls {
    border: 1px solid #e5e5e5;
    background-color: #ffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    padding: 15px 35px 15px 15px;
    .LinkedImgStyle {
      position: absolute;
      cursor: pointer;
      top: 14px;
      right: 15px;
      img {
        width: 22px;
      }
    }
  }
}

.DetailWrap {
  position: relative;
  margin-bottom: 14px;
  img {
    position: absolute;
    left: 0px;
    top: 5px;
    width: 13px;
  }

  h6 {
    font-size: 15px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0px;
    padding-left: 30px;
  }
}

.tableProfile {
  table {
    margin-top: 30px;
    border: 1px solid #0003;
    th,
    td {
      padding: 15px;
      color: #000;
      font-family: Sora;
      line-height: normal;
      width: 50%;
    }
    th {
      background: #dee4ed;
      font-size: 14px;
    }
    td {
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.ButtonWrap {
  :global(.btnwrap) {
    svg {
      color: #ffff !important;
    }
  }
  a {
    color: #fff;
  }
  .BtnNewSpace {
    margin-right: 15px;
    &:last-child {
      margin-right: 0px;
    }
    @media screen and (max-width: 1199px) {
      margin-right: 10px;
    }
  }
  .BtnColorBlue {
    background-color: $primary-color;
    transition: 0.8s;
    &:hover {
      transition: 0.8s;
      background-color: black;
    }
  }
  button {
    border: none;
    padding: 6px 20px 7px;

    @media screen and (max-width: 1199px) {
      padding: 6px 10px 7px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
    }

    span {
      color: #fff;
      font-size: 13px;
      margin-right: 10px;
    }
  }
}

.cautionStatement {
  span {
    color: #b10000;
  }
}

.MainBoxStyle {
  padding: 20px 0;
  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }

  .ParaStyle {
    margin-top: 20px;
    font-size: 13px;
    font-weight: 300;
    color: #ffff !important;
    text-align: left !important;
    max-width: unset !important;
  }

  .BorderStyle {
    padding-bottom: 5px;
    border-bottom: 1px solid #0852d37a;
  }
  .BorderStyleTwo {
    padding-bottom: 5px;
    border-bottom: 1px solid #3d63a763;
  }
  .BorderStyleThree {
    padding-bottom: 5px;
    border-bottom: 1px solid #ffffff24;
  }
  h5 {
    color: #000000;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 24px;
    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
      font-size: 15px;
    }
  }
  @media screen and (max-width: 992px) {
    margin-bottom: 10px;
  }
  .BoxNew {
    height: 600px;
    padding: 25px 20px;
    border-radius: 5px;
    color: #ffff;

    @media screen and (max-width: 1499px) {
      height: 600px;
    }
    @media screen and (max-width: 1200px) {
      height: 600px;
    }
    @media screen and (max-width: 1199px) {
      padding: 20px 15px;
      margin-bottom: 20px;
      height: 400px;
    }
    @media screen and (max-width: 789px) {
      height: auto;
    }
    h6 {
      font-family: "Darker Grotesque", sans-serif;
      font-weight: 600;
      padding-bottom: 5px;
      font-size: 18px;
      max-width: 300px;
      @media screen and (max-width: 767px) {
        padding-bottom: 5px;
        font-size: 17px;
      }
      @media screen and (max-width: 1199px) {
        font-size: 17px !important;
      }
    }
  }
  .BoxSec {
    .ColorBluePara {
      color: #003a9f !important;
      text-align: left !important;
    }
    h4 {
      line-height: 30px;
      color: #fff;
      font-size: 24px;
      font-weight: 600;
      font-family: "Darker Grotesque", sans-serif;
      margin-bottom: 0px;
      text-align: left !important;
      @media screen and (max-width: 1199px) {
        max-width: 100%;
        font-size: 22px;
      }
    }
    p {
      @include commonPara();
      color: #fff !important;
      font-size: 13px !important;
      margin-bottom: 10px !important;
      line-height: 25px !important;
      @media screen and (max-width: 992px) {
        margin-bottom: 20px;
        color: #fff !important;
      }
    }
    .IndustryAdvisoryBulletNav {
      padding-top: 10px;
    }

    @media screen and (max-width: 992px) {
      margin-bottom: 20px;
    }
  }
  .Blue {
    background-color: #e5efff;
    height: 155px;
  }
  .LightBlue {
    background-color: #003ea8;
  }
  .DarkBlue {
    background-color: #002667;
  }
  .Green {
    background-color: #6a8816;
  }
  .Black {
    background-color: black;
  }
  .Box {
    padding: 30px 40px 20px;
    border-radius: 5px;
  }

  .SpanColor {
    color: #8faa44 !important;
    text-decoration: 1px #8faa44 underline;
  }
}

.tableMainPlacement {
  table {
    width: 100%;
    margin-top: 30px;
    th,
    td {
      padding: 15px;
      border: 1px solid #0003;
      color: #000;
      font-family: Sora;
      line-height: normal;
    }
    th {
      background: #dee4ed;
      font-size: 14px;
      text-align: center;
    }
    td {
      font-weight: 400;
      font-size: 14px;
    }
  }
}