@import "../../styles/util";
.relatedComponentBg {
    padding: 60px 0px 90px;
     background: url(../../assets/images/homepageaccredition/background-vector.png) 
     no-repeat right bottom #e1e8f4;
    @media screen and (max-width: 992px) {
        padding: 40px 0px 40px;
        background:#e1e8f4;
    }
    @include max(767.98) {
        padding: size(30) 0;
    }
    h3 {
      
        text-align: left;
        font-weight: 600;
      
        margin-bottom: 30px;
        font-size: 45px;
        line-height: 45px;
        @media screen and (max-width: 992px) {
            margin-bottom: 31px;
            font-size: 38px; line-height: 29px;
        }
        @media screen and (max-width: 570px) {
            margin-bottom: 20px;
            font-size: 32px; line-height: 29px;
        }
        
      
    }
    .programcategorySliderWrap {
        position: relative;
        :global(.swiper-arrows-green-fill){
            width: 50px!important;
height: 50px!important; background-color: #8FAA44!important;
border: 1px solid #8FAA44!important; border-radius: 50%;
@media screen and (max-width: 570px) {
    width: 35px!important;
    height: 35px!important; 
}
svg{
    width: 15px!important;
height: 15px!important; color:#fff!important;
}
        }
        .categorySlider {
            figure {
                margin-bottom: 15px;
                @include max(767.98) {
                    margin-bottom: 15px;
                }
                @media screen and (max-width: 570px) {
                    margin-bottom: 10px;
                                   }
                @include aspectRatio(668, 376);
                img {
                    transition: 0.2s all ease-in-out;
                }
            }
            figcaption {
                display: flex;
                align-items: center;
                @media screen and (max-width: 570px) {
 justify-content: center;
                }
                h5 {
                    color: #000;
                    transition: 0.2s all ease-in-out;
                    font-weight: 400; font-size: 15px;
                    @media screen and (max-width: 992px) {
                        font-size: 14px;
                    }
                }
                svg {
                    margin-left: 11px;
                    transition: 0.2s all ease-in-out;
                    transform: rotate(47deg);
                }
            }
            &:hover {
                figure {
                    img {
                        transform: scale(1.02);
                    }
                }
                figcaption {
                    h5 {
                        color: #8FAA44;
                    }
                    svg {
                        color: #8FAA44;
                    }
                }
            }
        }
        .swiperBtnWrapper {
            display: flex;
            z-index: 1;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            top: 40%;
            transform: translateY(-45%);
            pointer-events: none;
            @include max(767.98) {
                position: static;
                transform: none;
                justify-content: center;
                top: inherit;
                padding-top: 20px;
            }
             :global(.slider-prev) {
                position: relative;
                left: -27px;
                pointer-events: all;
                border-radius: 50%;
                transform: rotateZ(182deg);
                display: flex;
                justify-content: center;
                align-items: center;
                @include max(767.98) {
                    left: -10px;
                }
            }
             :global(.slider-next) {
                position: relative;
                right: -27px;
                pointer-events: all;
                @include max(767.98) {
                    right: 0;
                }
            }
             :global(.swiper-button-disabled) {
                opacity: 0;
                @include max(767.98) {
                    opacity: 0.5;
                }
            }
        }
    }
}